@import '../impulse-spinner/ImpulseSpinner.mixin.scss';

.container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  @include impulseSpinner($size: 1.5rem);
}
