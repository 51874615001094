@import './theme.variables.scss';
@import 'antd/dist/antd.css';
// Reset gutters.
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%;
  width: 100%;
  overflow: auto;

  font-family: $font-family-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
}
