@import './fonts/ARS/style.scss';

// Colors.
$primary-color: #FD5300; // Primary color.
$light-primary-color: rgba($primary-color, 0.1);
$dark-primary-color: #D14600;
$primary-color-light: #ff8e56d8; // Primary color.
$secondary-color: #009DB8; // Secondary color.
$color-progress-background: #0081961A;
$color-progress: #008196;
$color-finish-background: #007F1E1A;
$color-finish: #008A15;
$grey: #1F2121;
$secondary-grey: #434748;
$light-grey: #7A80817f;
$lighty-grey: #757575;
$green: #008533;
$link-color: #3498db; // Link color.
$red: #EB0018;
$ko-color: #FF0000;
$input-value-not-good: #EB00180D;

$background-color: hsl(0, 0%, 100%);

$white: #FFFFFF;
$black: #000000;

$light-grey-table: #e0e0e0; // Light grey.
$light-grey-accordion: #F6F6F6; // Light grey.
$grey-sapid: #919191;
$border-light: #707070;

$shadow-card: #00000033;

// Metrics
$header-size: 50px;
$border-radius: 5px;

// Fonts.
$font-family-title: Ars, sans-serif;
$font-family-text: Ars;
