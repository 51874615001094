@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-Light.otf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-LightItalic.otf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-RegularItalic.otf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-BoldItalic.otf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Ars';
  font-display: swap;
  src: url('./ARSMaquettePro-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
